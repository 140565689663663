const ENDPOINT = {
  USER_SIGN_IN: 'user/login',
  USER_SIGN_IN_FACEBOOK: 'user/web/login/facebook',
  USER_SIGN_IN_GOOGLE: 'user/web/login/google',
  USER_SIGN_IN_APPLE: 'orderUser/login/apple', //To do
  USER_SIGN_UP: 'user/register',
  USER_UPDATE: 'user/profile',
  USER_CHANGE_PASSWORD: 'user/changePassword',
  USER_FORGOT_PASSWORD: 'user/forgot?web',
  USER_CHECK_TOKEN: 'user/checkResetToken/:token',
  USER_SET_PASSWORD: 'user/setNewPassword/:token',
  SUBSCRIBE: 'subscribe',
  SUBSCRIBE_ENROL: 'subscribe/enrol',
  SUBSCRIBE_CAMPAIGN: 'subscribe/campaign',
  UNSUBSCRIBE: 'unsubscribe/:token',
  PDF: 'download/pdf/:filename',
  MP3: 'download/mp3/:filename',
  KIDPOD: 'productSet',
  KIDPOD_LIST: 'productSet/list',
  KIDPOD_DETAIL: 'productSet/:id',
  KIDPAL: 'product',
  KIDPAL_LIST: 'product/list',
  PTODUCT_BY_CATALOG: 'product/catalog/:slug',
  KIDPAL_DETAIL: 'product/:id',
  EVOUCHER: 'evoucher/check',
  EVOUCHER_ROLLBACK: 'evoucher/rollback',
  SUBSCRIPTION: 'subscription/subsPackage',
  MY_SUSPEND: 'suspend-subscription/my-suspend',
  SUSPEND_SUBSCRIPTION: 'suspend-subscription',
  SUBSCRIPTION_UPDATE_CARD: 'payment/apChangeCardSchPay',
  FORGOT_PASSWORD: 'forgot-password',
  CHECKOUT: 'payment/checkout_website',
  SUBSCRIPTION_RENEWAL: 'payment/subscription-renewal',
  SUBSCRIPTION_CONVERT: 'payment/subscription-convert',
  VERIFY_PAYMENT: 'payment/:gateway/callback',
  MOMO_VERIFY_PAYMENT: 'payment/momo/verify',
  VNPAY_VERIFY_PAYMENT: 'payment/vnpay/verify',
  CITIES: 'address/city/:code',
  COUNTRIES: 'address',
  DISTRICT: 'address/city/:id/district',
  WARD: 'address/district/:id/ward',
  MY_ORDER: 'preOrder/myOrder',
  MY_REFERRAL_ORDER: 'order/myReferralOrder',
  ORDER_DETAIL: 'preOrder/:id',
  AUDIO_TAG: 'audio/tag/:tag',
  AUDIO_STREAM_SIGNED: 'audioWeb/signedStreamFull/:id',
  AUDIO_STREAM_DEMO: 'audio/signedStream/:id',
  AUDIO_STREAM_FULL: 'audio/signedStreamFull/:id',
  AUDIO_BY_CATEGORY: 'audio/category',
  AUDIO: 'audio/homePage',
  AUDIO_DETAIL_BY_SLUG: 'audioWeb/slug/:slug',
  AUDIO_DETAIL_BY_ID: 'audio/:id',
  BLOG: 'blog',
  BLOG_RANDOM: 'blog/random',
  BLOG_DETAIL: 'blog/:id',
  SITE_MAP: 'sitemap',
  BUNDLE_SET: 'productSet/catalog/:slug',
  PRODUCT_CATEGORY: 'product/catalog',
  ORDER_TEMPORARY: 'order-temporary',
  ORDER_TEMPORARY_DETAIL: 'order-temporary/:id',
  FOMO_SNIPPET: 'fomoSnippet',
  REVIEW_TOKEN: 'review/token/:token',
  REVIEW_DETAIL: 'review/:id',
  REVIEW_LIST: 'review',
  REVIEW_AVERAGE_RATING: 'review/average/rating',
  CAMPAIGN: 'campaign/:shortUrl',
  TROUBLESHOOT: 'troubleshoot',
  LOG_PAYMENT_FAIL: 'logPaymentFail',
  CREATIVE: 'creative',
  CREATIVE_DETAIL: 'creative/:id',
  UGC: 'ugc',
  CHATBOT: 'chatbot',
  CONVERSATION: 'conversation',
  PENDING_ORDER_EVENT: 'order/pendingOrderEvent',
  AFFILIATE_REQUEST: 'affiliateRequest',
  TOTAL_MINUTES: 'stats/totalMinutes',
  PLAYLIST_BY_TAG: 'playlist/tag/:tag',
  PLAYLIST_BY_CATEGORY_SLUG: 'playlist/category',
  PLAYLIST_DETAIL_BY_ID: 'playlist/:id',
  AUDIO_RECOMMEND: 'audio/recommendation',
  DAILY_POD: 'kdaily/channel/:slug/web',
}

export default ENDPOINT
