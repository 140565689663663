import { createSlice } from '@reduxjs/toolkit'
import { SUSPEND_STEP } from '../constants'

export const slice = createSlice({
  name: 'suspendSubscription',
  initialState: {
    step: SUSPEND_STEP.REASON,
    reason: '',
    name: '',
    address: '',
    phone: '',
    accountName: '',
    accountNumber: '',
    bankName: '',
    bankBranch: '',
    suspendMethod: '',
  },
  reducers: {
    setReason: (state, action) => {
      return { ...state, reason: action.payload }
    },
    setStep: (state, action) => {
      return { ...state, step: action.payload }
    },
    setContactInformation: (state, action) => {
      return {
        ...state,
        name: action.payload.name,
        address: action.payload.address,
        phone: action.payload.phone,
        accountName: action.payload.accountName,
        accountNumber: action.payload.accountNumber,
        bankName: action.payload.bankName,
        bankBranch: action.payload.bankBranch,
        suspendMethod: action.payload.suspendMethod,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setReason, setStep, setContactInformation } = slice.actions

export default slice.reducer
