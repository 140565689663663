const httpStatusCode = {
  BAD_REQUEST: 400,
  CANCEL: 299,
  CREATED: 201,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
  OK: 200,
  STORE_GROUP_HAS_CHILDREN: 444,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
}

export default httpStatusCode
