import React from 'react'
import getImage from './utils/getImage'

const Maintenance = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <img src={getImage('maintenance.webp')} alt="maintenance" />
      <p>We are Under Maintenance</p>
    </div>
  )
}

export default Maintenance
