import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'

import crosstabSync from './crosstab'
import createRootReducer from './reducers'
import rootSaga from './common/rootSaga'
import history from './history'

const persistConfig = {
  key: 'kidpodRoot',
  storage,
  whitelist: ['cart', 'user', 'router', 'common', 'chatbot'], //Things u want to persist
  blacklist: [], //Things u dont
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const sagaMiddleware = createSagaMiddleware()

function buildStore() {
  const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(sagaMiddleware)
        .concat(routerMiddleware(history)),
    enhancers: [sentryReduxEnhancer],
  })

  const persistor = persistStore(store)
  crosstabSync(persistor)

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history))
    })
  }

  return { persistor, store }
}

const store = buildStore()

sagaMiddleware.run(rootSaga)

export default store
