import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'audioPlayer',
  initialState: {
    audioListPlay: [],
    idPlaying: '',
    status: '',
  },
  reducers: {
    setAudioListPlay: (state, action) => {
      return { ...state, audioListPlay: action.payload }
    },
    setIdPlaying: (state, action) => {
      return { ...state, idPlaying: action.payload }
    },
    setStatus: (state, action) => {
      return { ...state, status: action.payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAudioListPlay, setIdPlaying, setStatus } = slice.actions

export default slice.reducer
