import { createSlice } from '@reduxjs/toolkit'
import * as dayjs from 'dayjs'
// import { initLng } from '../utils/locales'
const initialDefault = {
  dialog: true,
  isShowNav: false,
  // language: initLng,
  language: 'vi',
  expiredDialogTime: '',
  isSubscriber: false,
  isMessenger: false,
  expiredDate: '',
  isSubscriberQuiz: false,
  abundantCart: false,
  stopFomoSnippet: dayjs().unix(),
  accessHelloBar: 0,
  iSubscribeHelloBar: false,
  isBought: false,
}

export const slice = createSlice({
  name: 'common',
  initialState: initialDefault,
  reducers: {
    toggleShowNav: (state, action) => {
      return { ...state, isShowNav: !state.isShowNav }
    },
    setNav: (state, action) => {
      return { ...state, isShowNav: action.payload }
    },
    setLanguage: (state, action) => {
      return { ...state, language: action.payload }
    },
    enableMessenger: (state, action) => {
      return { ...state, isMessenger: action.payload }
    },
    setSubscribeDialog: (state, action) => {
      return { ...state, dialog: action.payload }
    },
    setExpiredDialogTime: (state, action) => {
      return { ...state, expiredDialogTime: action.payload }
    },
    setIsSubscriber: (state, action) => {
      return { ...state, isSubscriber: action.payload }
    },
    expiredStore: (state, action) => {
      return { ...state, expiredDate: action.payload }
    },
    clearCommon: () => {
      return { ...initialDefault }
    },
    setIsSubscriberQuiz: (state, action) => {
      return { ...state, isSubscriberQuiz: action.payload }
    },
    setAbundantCart: (state, action) => {
      return { ...state, abundantCart: action.payload }
    },
    setStopFomoSnippet: (state, action) => {
      return { ...state, stopFomoSnippet: action.payload }
    },
    setAccessHelloBar: (state, action) => {
      return { ...state, accessHelloBar: state.accessHelloBar + action.payload }
    },
    setSubscribeHelloBar: (state, action) => {
      return { ...state, iSubscribeHelloBar: action.payload }
    },
    setBoughtHelloBar: (state, action) => {
      return { ...state, isBought: action.payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  toggleShowNav,
  setNav,
  setLanguage,
  setSubscribeDialog,
  setExpiredDialogTime,
  setIsSubscriber,
  enableMessenger,
  expiredStore,
  clearCommon,
  setIsSubscriberQuiz,
  setAbundantCart,
  setStopFomoSnippet,
  setAccessHelloBar,
  setSubscribeHelloBar,
  setBoughtHelloBar,
} = slice.actions

export default slice.reducer
