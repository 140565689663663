import React from 'react'

const Progress = {
  count: 0,
  runningTimerId: null,
  hidingTimerId: null,
  state: 'hidden',

  toHiddenState() {
    var element = document.getElementById('progress-element')
    if (element) {
      element && element.setAttribute('data-state', 'hidden')
      Progress.hidingTimerId = null
    }
  },

  Component(props) {
    return (
      <div
        className="loader-60devs"
        data-state={Progress.hidden}
        id="progress-element"
      >
        <div className="loader-60devs-progress" />
      </div>
    )
  },

  show() {
    if (++Progress.count > 1) {
      return
    } else if (Progress.hidingTimerId) {
      clearTimeout(Progress.hidingTimerId)
      Progress.hidingTimerId = null
      return
    }

    var element = document.getElementById('progress-element')
    // let progressEl = element.querySelector('.loader-60devs-progress')
    if (element) {
      element.setAttribute('data-state', 'hidden')
      // the only working way to restart a transition on firefox
      // progressEl.outerHTML = progressEl.outerHTML
      // element.offsetHeight
      element.setAttribute('data-state', '')
      // element.offsetHeight
      element.setAttribute('data-state', 'running')
    }
  },
  hide() {
    if (--Progress.count > 0) {
      return
    }

    var element = document.getElementById('progress-element')
    element && element.setAttribute('data-state', 'finishing')
    Progress.hidingTimerId = setTimeout(Progress?.toHiddenState, 500)
  },
  hideAll() {
    Progress.count = 1
    Progress.hide()
  },
  isVisible() {
    var element = document.getElementById('progress-element')
    return element.getAttribute('data-state') !== 'hidden'
  },
}

export default Progress
