import common from './commonSlice'
import popup from './popupSlice'
import suspendSubscription from './suspendSubscriptionSlice'
import chatbot from './chatbotSlice'
import audioPlayer from './audioPlayerSlice'

export default {
  common,
  popup,
  suspendSubscription,
  chatbot,
  audioPlayer,
}
