import React from 'react'
//import ReactDOM, { hydrate } from 'react-dom'
// import { AppContainer } from 'react-hot-loader'
// import Loadable from 'react-loadable'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'

import store from './configureStore'
import history from './history'
import './assets/loaderProgress.css'
import './ReactotronConfig'
import './i18n'
import './assets/style.css'
import App from './App'
import ReactDOM from 'react-dom/client'
import getImage from './utils/getImage'
import Maintenance from './Maintenance'

//const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Maintenance />
  </React.StrictMode>
)

// root.render(
//   <React.StrictMode>
//     <Provider store={store.store}>
//       <PersistGate loading={null} persistor={store.persistor}>
//         <App history={history} />
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>
// )

// const funcRender = rootElement.hasChildNodes() ? hydrate : ReactDOM.render

// const render = () => {
//   Loadable.preloadReady().then(() => {
//     funcRender(
//       <AppContainer>
//         <Provider store={store.store}>
//           <PersistGate loading={null} persistor={store.persistor}>
//             <App history={history} />
//           </PersistGate>
//         </Provider>
//       </AppContainer>,
//       rootElement
//     )
//   })
// }

// render()
// // Hot reloading
// if (module.hot) {
//   // Reload components
//   module.hot.accept('./App', () => {
//     render()
//   })
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
