import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'popup',
  initialState: {
    showQuiz: false,
    userCloseQuiz: false,
    userOpenQuiz: false,
    showExitIntent: false,
    showCart: false,
    showHelloBar: true,
    showNotifyBar: true,
  },
  reducers: {
    setShowQuiz: (state, action) => {
      return { ...state, showQuiz: action.payload }
    },
    setUserCloseQuiz: (state, action) => {
      return { ...state, userCloseQuiz: action.payload }
    },
    setUserOpenQuiz: (state, action) => {
      return { ...state, userOpenQuiz: action.payload }
    },
    setShowExitIntent: (state, action) => {
      return { ...state, showExitIntent: action.payload }
    },
    setShowCart: (state, action) => {
      return { ...state, showCart: action.payload }
    },
    setShowHelloBar: (state, action) => {
      return { ...state, showHelloBar: action.payload }
    },
    setShowNotifyBar: (state, action) => {
      return { ...state, showNotifyBar: action.payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setShowQuiz,
  setShowExitIntent,
  setUserCloseQuiz,
  setUserOpenQuiz,
  setShowCart,
  setShowHelloBar,
  setShowNotifyBar,
} = slice.actions

export default slice.reducer
