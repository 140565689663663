import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import slice from '../slice'
import user from '../modules/user/global/state'
import cartReducer from '../modules/cart/cartSlice'

const rootReducer = (history) =>
  combineReducers({
    common: slice.common,
    popup: slice.popup,
    suspendSubscription: slice.suspendSubscription,
    user,
    router: connectRouter(history),
    cart: cartReducer,
    chatbot: slice.chatbot,
    audioPlayer: slice.audioPlayer,
  })

export default rootReducer
