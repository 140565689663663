import createTheme from '@material-ui/core/styles/createTheme'

export default function () {
  return createTheme({
    palette: {
      primary: {
        main: '#ff993d',
        contrastText: '#fff',
      },
      secondary: {
        main: '#4759A7',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
      body1: {
        fontSize: '1.6rem',
      },
      button: {
        fontSize: '1.4rem',
      },
    },
  })
}
