export const commonConstants = {
  SUBSCRIBE_DIALOG: 'SUBSCRIBE_DIALOG',
  ACTIVE_MENU: 'ACTIVE_MENU',
  FETCH_START: 'FETCH_START',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
}

export const language = {
  VI: 'vi',
  EN: 'en',
}

export const userConstants = {
  LOGIN: 'USERS_LOGIN',
  LOGOUT: 'USERS_LOGOUT',
  USERS: 'USERS',
  DELETE: 'USER_DELETE',
  TOGGLE_LOCK: 'USER_TOGGLE_LOCK',
}

export const TYPE_CART = {
  CONVERT: -1,
  BUY: 0,
  MONTH_1: 1,
  MONTH_3: 3,
  MONTH_6: 6,
}

export const TYPE_PRODUCT = {
  KIDPOD: 1,
  KIDPAL: 2,
}

export const TYPE_PRODUCT_VALUE = {
  [TYPE_PRODUCT.KIDPOD]: 'Kidpod',
  [TYPE_PRODUCT.KIDPAL]: 'Kidpal',
}

export const PRICE_MONTH_3 = 690000

export const PAYMENT = {
  COD: 1,
  ONEPAY: 2,
  INTERNET_BANKING: 3,
  ASIAPAY: 4,
  MOMO: 5,
  VNPAY: 6,
}

export const CART_TYPE = {
  SALE: 1,
  ENROL: 2,
}

export const ORDER_STATUS = {
  PROCESSING: 1,
  DELIVERING: 2,
  SUCCESSFUL: 3,
  CANCELED: 4,
  ONGOING: 0,
  FAIL: 5,
}

export const TYPE_PRICE = {
  MONEY: 1,
  PERCENT: 2,
  GIFT: 3,
}

export const SHIPPING_COST = 0
export const SHIPPING_COST_WITHOUT_KIDPOD = 19000
export const FREE_BOOKPOD = ['64401398c1569c0026f1ef9c']
export const SUBSCRIBER_TYPE = {
  PRE_ORDER: 1,
  PRE_ORDER_ENROL: 2,
  PRE_ORDER_ENROL_FACEBOOK: 3,
  QUIZ: 4,
  EXIT_INTENT: 5,
  HELLO_BAR: 6,
  HELLO_BAR_VOUCHER: 7,
  REFERRAL: 8,
  STORYTIME_CMC: 9,
  BIRTHDAY_KIDPOD: 10,
  GIFT: 11,
  SCHOOL: 12,
}

export const EXPIRED_CLEAR_STORE = '23-06-2022'
export const STEPPER = {
  CART: 0,
  CART_CONTACT_INFORMATION: 1,
  PAYMENT: 2,
  COMPLETE: 3,
}

export const SUSPEND_STEP = {
  REASON: 1,
  OFFER: 2,
  SUSPEND_INFO: 3,
  COMPLETE: 4,
}

export const SUSPEND_METHOD = {
  AT_COMPANY: 'company',
  AT_HOME: 'home',
}

export const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  COMPLETE: 4,
}

export const TYPE_UGC = {
  FACEBOOK: 1,
  VIDEO: 2,
}

export const APPLIED_TYPE = {
  KIDPOD: 1,
  ALL: 2,
  SHIPPING: 3,
  ALL_WITHOUT_KIDPOD: 4,
}

export const METHOD_TYPE = {
  BUY: 1,
  RENT: 2,
}

export const VOUCHER_TYPE = {
  MONEY: 1,
  PERCENT: 2,
}

export const CHATBOT_TYPE = {
  EMAIL: 1,
  PHONE: 2,
}

export const CHATBOT_MESSAGE_TYPE = {
  BOT: 1,
  BOT_RES: 2,
  USER_RES: 3,
}

export const SLIDE_ID = 'slide-chatbot-button'
export const SUGGEST_BOX_ID = 'suggest-chatbot-button'
export const GREETING_ID = 'greeting-initial-id'
