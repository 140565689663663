import React from 'react'
//import { PrerenderedComponent } from 'react-prerendered-component'
import loadable from '@loadable/component'
import Loading from '../components/Spinner'
import ROUTER_NAME from '../configs/routerName'
import { Navigate } from 'react-router-dom'

// export const prerenderedLoadable = (dynamicImport) => {
//   const LoadableComponent = loadable(dynamicImport)
//   return React.memo((props) => (
//     // you can use the `.preload()` method from react-loadable or react-imported-component`
//     <PrerenderedComponent live={LoadableComponent.load()}>
//       <LoadableComponent {...props} />
//     </PrerenderedComponent>
//   ))
// }

// Noted: can't create function, dynamic import not working :v
// const routeItems = [
//   {
//     path: ROUTER_NAME.HOME,
//     component: loadable(() => import('../modules/audio/DailyPod'), {
//       fallback: <Loading />,
//     }),
//   },
// ]

const routeItems = [
  {
    path: ROUTER_NAME.LOGIN,
    component: loadable(() => import('../modules/user/NewLogin'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CREATE_ACCOUNT,
    component: loadable(() => import('../modules/user/NewSignup'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SIGN_UP,
    component: loadable(() => import('../modules/user/SignUp'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.HOME,
    component: loadable(() => import('../modules/home/Home'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.HOW_IT_WORK,
    component: loadable(() => import('../modules/howItWork/HowItWork'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.TOYPOD,
    component: loadable(() => import('../modules/toypod/Toypod'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SCHOOL,
    component: loadable(() => import('../modules/school/School'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.BOOKPOD,
    component: loadable(() => import('../modules/bookpod/Bookpod'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.COMBO_SET,
    component: loadable(() => import('../modules/comboSet/ComboSet'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.ABOUT,
    component: loadable(() => import('../modules/redirect/AboutOld'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.ABOUTUS,
    component: loadable(() => import('../modules/about/About'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PRODUCT,
    component: loadable(() => import('../modules/product/Product'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SHOP,
    component: loadable(() => import('../modules/shop/NewShop'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SHOP_PRODUCT_SET,
    component: loadable(() => import('../modules/shop/ProductSet'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SHOP_PRODUCT,
    component: loadable(() => import('../modules/shop/Product'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KIDPOD_CLUB,
    component: loadable(() => import('../modules/kidpodClub/KidpodClub'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CONTACT_US,
    component: loadable(() => import('../modules/contact/Contact'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CONTACTUS,
    component: loadable(() => import('../modules/contact/Contact'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUBSCRIBE,
    component: loadable(() => import('../modules/subscribe/Subscribe'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.UNSUBSCRIBE,
    component: loadable(() => import('../modules/subscribe/UnSubscribe'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KEY_IN_CODE,
    component: loadable(() => import('../modules/cart/KeyInCode'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.REPURCHASE,
    component: loadable(() => import('../modules/cart/Repurchase'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PAYMENT,
    component: loadable(() => import('../modules/cart/Payment'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.COMPLETE,
    component: loadable(() => import('../modules/cart/Complete'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.BANKING_COMPLETE,
    component: loadable(() => import('../modules/cart/CompleteViaInternetBanking'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.COD_COMPLETE,
    component: loadable(() => import('../modules/cart/CompleteViaInternetBanking'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.ASIA_PAY_COMPLETE,
    component: loadable(() => import('../modules/cart/CompleteViaAsiaPay'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.MOMO_COMPLETE,
    component: loadable(() => import('../modules/cart/CompleteViaMomo'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.VNPAY_COMPLETE,
    component: loadable(() => import('../modules/cart/CompleteViaVNPay'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KID_PAL,
    component: loadable(() => import('../modules/kidpal/Kidpal'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KID_POD,
    component: loadable(() => import('../modules/redirect/KidpodOld'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.STARTER,
    component: loadable(() => import('../modules/kidpod/Kidpod'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KID_POD_APP,
    component: loadable(() => import('../modules/redirect/KidpodAppOld'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KIDPODAPP,
    component: loadable(() => import('../modules/kidpodApp/KidpodApp'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KID_POD_DETAIL,
    component: loadable(() => import('../modules/kidpod/KidpodDetail'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KID_PAL_DETAIL,
    component: loadable(() => import('../modules/kidpal/NewKidpalDetail'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KID_PAL_DETAIL_OLD,
    component: loadable(() => import('../modules/redirect/KidpalDetailOld'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.GIVE_AWAY_HTML,
    component: loadable(() => import('../modules/redirect/giveaway'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CART,
    component: loadable(() => import('../modules/cart/Cart'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CART_CONFIRM,
    component: loadable(() => import('../modules/cart/CartConfirm'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.KIDPOD_CLUB_DETAIL,
    component: loadable(() => import('../modules/kidpodClub/Detail'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.MY_ACCOUNT,
    component: loadable(() => import('../modules/profile/MainAccount'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PROFILE,
    component: loadable(() => import('../modules/profile/MyAccount'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.AFFILIATE,
    component: loadable(() => import('../modules/profile/Affiliate'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.ORDER_HISTORY,
    component: loadable(() => import('../modules/order/OrderHistory'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CART_CONTACT_INFORMATION,
    component: loadable(() => import('../modules/cart/ContactInformation'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.FORGOT_PASSWORD,
    component: loadable(() => import('../modules/user/ForgotPassword'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.NEW_PASSWORD,
    component: loadable(() => import('../modules/user/NewPassword'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.ORDER_DETAIL,
    component: loadable(() => import('../modules/order/OrderDetail'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.AUDIO,
    component: loadable(() => import('../modules/audio/Audio'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.DAILY_POD,
    component: loadable(() => import('../modules/dailypod/DailyPod'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SECRETGIFT,
    component: loadable(() => import('../modules/secretgift/Secretgift'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.GENRE,
    component: loadable(() => import('../modules/audio/Genre'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.AUDIO_DETAIL,
    component: loadable(() => import('../modules/audio/AudioDetail'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PLAYLIST_DETAIL,
    component: loadable(() => import('../modules/audio/PlaylistDetail'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUB_REGISTRATION,
    component: loadable(() => import('../modules/landing/SubRegistration'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.LANDING_PAGE_FREE_BOOKPOD,
    component: loadable(() => import('./ForwardUrl'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.BIRTHDAY,
    component: loadable(() => import('../modules/landing/birthday/Birthday'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUB_REGISTRATION_THANK,
    component: loadable(() => import('../modules/landing/SubRegistrationThank'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUB_REGISTRATION_FACEBOOK,
    component: loadable(() => import('../modules/landing/SubRegistrationFacebook'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUB_REGISTRATION_THANK_FACEBOOK,
    component: loadable(
      () => import('../modules/landing/SubRegistrationThankFacebook'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.READ_PDF,
    component: loadable(() => import('../modules/pdf/DetailPDF'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.MP3,
    component: loadable(() => import('../modules/mp3/DownloadMp3'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PRIVACY_POLICY,
    component: loadable(() => import('../modules/privacyPolicy/PrivacyPolicy'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PAYMENT_POLICY,
    component: loadable(() => import('../modules/privacyPolicy/PaymentPolicy'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PRODUCT_RETURN,
    component: loadable(() => import('../modules/privacyPolicy/ProductReturn'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CHANGE_PASSWORD,
    component: loadable(() => import('../modules/user/ChangePassword'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PAGE_500,
    component: loadable(() => import('../components/Page500'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.BENEFIT_PAYMENT,
    component: loadable(() => import('../modules/product/BenefitPayment'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUBSCRIPTION,
    component: loadable(() => import('../modules/subscription/UserSubscription'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.SUBSCRIPTION_PAYMENT,
    component: loadable(
      () => import('../modules/subscription/PaymentSubscription'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.SUSPEND_SUBSCRIPTION,
    component: loadable(
      () => import('../modules/subscription/SuspendSubscription'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.SUBSCRIPTION_UPDATE_CARD,
    component: loadable(
      () => import('../modules/subscription/SubscriptionUpdateCard'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.CONVERT_SUBSCRIPTION,
    component: loadable(
      () => import('../modules/subscription/ConvertSubscription'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.CONVERT_SUBSCRIPTION_PAYMENT,
    component: loadable(
      () => import('../modules/subscription/ConvertSubscriptionPayment'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.REFERRAL,
    component: loadable(() => import('../modules/profile/Referral'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.PRODUCT_SLUG,
    component: loadable(() => import('../modules/product/ProductSlug'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.REVIEW_FORM,
    component: loadable(() => import('../modules/review/ProductReview'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.REVIEW_FORM_SUCCESS,
    component: loadable(() => import('../modules/review/ProductReviewSuccess'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.REVIEW_LIST,
    component: loadable(() => import('../modules/review/ProductKidpodReview'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.AFFILIATE_REQUEST,
    component: loadable(
      () => import('../modules/affiliateRequest/affiliateRequest'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.AFFILIATE_REQUEST_SUCCESS,
    component: loadable(
      () => import('../modules/affiliateRequest/affiliateRequestSuccess'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ROUTER_NAME.STORYTIME_CMC,
    component: loadable(() => import('../modules/landing/StoryTimeCMC'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.CAMPAIGN,
    component: loadable(() => import('../modules/campaign/Campaign'), {
      fallback: <Loading />,
    }),
  },
  {
    path: ROUTER_NAME.REVIEW_VIDEO,
    component: loadable(() => import('../modules/ugc/Ugc'), {
      fallback: <Loading />,
    }),
  },
]

export default routeItems
