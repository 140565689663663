const routerName = {
  HOME: '/',
  ABOUT: '/about',
  ABOUTUS: '/aboutus',
  PRODUCT: '/product',
  SHOP: '/shop',
  SHOP_PRODUCT_SET: '/shop/:name-set',
  SHOP_PRODUCT: '/shop/:slug',
  KIDPOD_CLUB: '/kidpod-club',
  KIDPOD_CLUB_DETAIL: '/kidpod-club/:slug/:id',
  CONTACT_US: '/contact-us',
  CONTACTUS: '/contactus',
  SUBSCRIBE: '/subscribe',
  UNSUBSCRIBE: '/unsubscribe/:token',
  KID_PAL: '/kidpal',
  KID_POD: '/kidpod',
  KID_POD_APP: '/kidpod-app',
  KIDPODAPP: '/kidpodapp',
  LOGIN: '/sign-in',
  SIGN_UP: '/sign-up',
  KID_POD_DETAIL: '/kidpod/:id',
  KID_PAL_DETAIL: '/product/:id',
  KID_PAL_DETAIL_OLD: '/kidpal/:id',
  KEY_IN_CODE: '/key-in-code',
  REPURCHASE: '/repurchase/:id',
  CART: '/cart',
  CART_CONFIRM: '/cart-confirm',
  PAYMENT: '/payment',
  COMPLETE: '/order-result',
  BANKING_COMPLETE: '/order-result/via-internet-banking',
  COD_COMPLETE: '/order-result/via-cod',
  ASIA_PAY_COMPLETE: '/order-result/via-asia-pay',
  MOMO_COMPLETE: '/order-result/via-momo',
  VNPAY_COMPLETE: '/order-result/via-vnpay',
  MY_ACCOUNT: '/my-account',
  PROFILE: '/profile',
  ORDER_HISTORY: '/order-history',
  TERM_OF_USE: '/term-of-use',
  TERM_AND_CONDITION: '/terms-and-conditions',
  CART_CONTACT_INFORMATION: '/cart-contact-information',
  CREATE_ACCOUNT: '/create-account',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/reset-password/:token',
  ORDER_DETAIL: '/order-detail/:id',
  GENRE: '/genre/:slug',
  AUDIO: '/audio',
  DAILY_POD: '/dailypod',
  AUDIO_DETAIL: '/audio/:id',
  PLAYLIST_DETAIL: '/playlist/:id',
  SUB_REGISTRATION: '/sub-registration',
  SUB_REGISTRATION_THANK: '/sub-registration-thank',
  SUB_REGISTRATION_FACEBOOK: '/sub-registration-fb',
  SUB_REGISTRATION_THANK_FACEBOOK: '/sub-registration-thank-fb',
  BIRTHDAY: '/uu-dai-sinh-nhat-kidpod',
  SUBSCRIPTION: '/subscription',
  SUBSCRIPTION_PAYMENT: '/subscription-payment',
  SUBSCRIPTION_UPDATE_CARD: '/subscription-update-card',
  SUSPEND_SUBSCRIPTION: '/suspend-subscription',
  CONVERT_SUBSCRIPTION: '/convert-subscription',
  CONVERT_SUBSCRIPTION_PAYMENT: '/convert-subscription-payment',
  REFERRAL: '/referral',
  READ_PDF: '/pdf/:filename',
  MP3: '/mp3/:filename',
  AFFILIATE: '/affiliate',
  // AUDIO_CATEGORY: '/audio/:slug',
  PAYMENT_POLICY: '/payment-policy',
  PRODUCT_RETURN: '/product-return',
  PRIVACY_POLICY: '/privacy-policy',
  GIVE_AWAY: '/soncagiveaway50campaign',
  GIVE_AWAY_HTML: '/soncagiveaway50campaign.html',
  CHANGE_PASSWORD: '/change-password',
  PAGE_500: '/error-500',
  BENEFIT_PAYMENT: '/benefit-payment-package/:slug',
  PRODUCT_SLUG: '/product-slug/:slug',
  REVIEW_FORM: '/review-form/:token',
  REVIEW_FORM_SUCCESS: '/review-form-success',
  REVIEW_LIST: '/review-list',
  AFFILIATE_REQUEST: '/ambassadors',
  AFFILIATE_REQUEST_SUCCESS: '/ambassadors/success',
  STORYTIME_CMC: '/storytime-cmc',
  CAMPAIGN: '/:uri',
  REVIEW_VIDEO: '/ugc/:type',
  HOW_IT_WORK: '/howitwork',
  STARTER: '/starter',
  TOYPOD: '/toypod',
  BOOKPOD: '/bookpod',
  COMBO_SET: '/comboset',
  SCHOOL: '/forteacher',
  SECRETGIFT: '/secretgift',
  LANDING_PAGE_FREE_BOOKPOD: '/try',
}

export default routerName
