import React from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { FeatureToggles } from '@paralleldrive/react-feature-toggles'
import { positions, Provider } from 'react-alert'
import { SnackbarProvider } from 'notistack'
import Grow from '@material-ui/core/Grow'
import * as Sentry from '@sentry/react'

import routes from './routes'
import useTheme from './hook/useTheme'
import { FEATURE } from './configs'
import { useDispatch, useSelector } from 'react-redux'
import { setAccessHelloBar } from './slice/commonSlice'
import Progress from './components/Progress'
import useLoadScript from './hook/useLoadScript'
import Loadable from 'react-loadable'
import Spinner from './components/Spinner'

const AlertTemplate = Loadable({
  loader: () => import('./components/AlertTemplate'),
  loading: () => <Spinner />,
})
const ScrollToTop = Loadable({
  loader: () => import('./components/ScrollToTop'),
  loading: () => <Spinner />,
})
// const QuizPopup = Loadable({
//   loader: () => import('./components/QuizPopup'),
//   loading: () => <Spinner />,
// })
const ExpiredStore = Loadable({
  loader: () => import('./components/ExpiredStore'),
  loading: () => <Spinner />,
})
const UpdateOrderTemporary = Loadable({
  loader: () => import('./components/UpdateOrderTemporary'),
  loading: () => <Spinner />,
})
const ChatComponent = Loadable({
  loader: () => import('./components/ChatComponent'),
  loading: () => <Spinner />,
})
const ExitIntentDialog = Loadable({
  loader: () => import('./components/ExitIntentDialog'),
  loading: () => <Spinner />,
})

const AudioPlayer = Loadable({
  loader: () => import('./components/AudioPlayer'),
  loading: () => <Spinner />,
})

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  containerStyle: {
    zIndex: 9999,
    top: '88px',
    paddingRight: '15px',
  },
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (error.statusCode === 404) return false
        if (failureCount < 2) return true
        return false
      },
    },

    mutations: {},
  },
})

const MAX_SNACK = 3

const App = ({ history }) => {
  useLoadScript()
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [showWidget, setShowWidget] = React.useState(false)

  const { common: { accessHelloBar } = {} } = useSelector((state) => state)
  const isAccessHelloBar = accessHelloBar > 1
  const [path] = window.location.pathname.substring(1).split('/')

  React.useEffect(() => {
    isAccessHelloBar || dispatch(setAccessHelloBar(1))
    const loadWidget = () => {
      setShowWidget(true)
      window && window.removeEventListener('scroll', loadWidget)
    }
    window && window.addEventListener('scroll', loadWidget)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Provider template={AlertTemplate} {...options}>
            <Progress.Component />
            <SnackbarProvider
              maxSnack={MAX_SNACK}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              TransitionComponent={Grow}
              classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
              }}
            >
              {showWidget && <ScrollToTop />}
              <FeatureToggles features={FEATURE}>
                {routes}
                {showWidget && <ChatComponent />}
              </FeatureToggles>
              <ExpiredStore />
              {/* <UpdateOrderTemporary /> */}
              {/* {showWidget && path !== 'mp3' && (
                <>
                  <ExitIntentDialog />
                </>
              )} */}
              <AudioPlayer />
            </SnackbarProvider>
          </Provider>
        </ThemeProvider>
      </ConnectedRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

const useStyles = makeStyles(() => ({
  error: {
    fontSize: '1.6rem',
  },
  success: {
    fontSize: '1.6rem',
  },
  warning: {
    fontSize: '1.6rem',
  },
  info: {
    fontSize: '1.6rem',
  },
}))

App.propTypes = {
  history: PropTypes.object,
}

export default Sentry.withProfiler(App)
