import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Route, Switch } from 'react-router-dom'
import Loading from '../components/Spinner'
import loadable from '@loadable/component'

import routeItems from './routes'
import { ROUTER_NAME } from '../configs'

const renderRoute = (item, index) => <Route exact key={index} {...item} />

const routes = (
  <TransitionGroup>
    <CSSTransition classNames="fade" timeout={300}>
      <Switch>
        {/* <Route
          exact
          path={ROUTER_NAME.ROOT}
          render={() => {
            return <Redirect to={ROUTER_NAME.HOME} />
          }}
        /> */}
        {/* <Route
          exact
          path={ROUTER_NAME.KID_POD}
          render={() => {
            return <Redirect to={ROUTER_NAME.STARTER} />
          }}
        /> */}
        {routeItems.map(renderRoute)}
        <Route
          component={loadable(() => import('../modules/notFound/NotFound'), {
            fallback: <Loading />,
          })}
        />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
)

export default routes
