import { commonConstants } from '../constants/index'
import Progress from '../components/Progress'
import { takeEvery } from 'redux-saga/effects'

function* start() {
  yield Progress.show()
}

function* success() {
  yield Progress.hide()
}

function* fail() {
  yield Progress.hide()
}

export function* watchFetchStart() {
  yield takeEvery(commonConstants.FETCH_START, start)
}

export function* watchFetchSuccess() {
  yield takeEvery(commonConstants.FETCH_SUCCESS, success)
}

export function* watchFetchFail() {
  yield takeEvery(commonConstants.FETCH_FAIL, fail)
}
