import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { set } from '../../../utils/localStorage'

const initialState = {}
export const moduleName = 'user'
const reducers = {
  logout: (state, action) => {
    storage.removeItem('persist:root')
    set('token', '')
    return {}
  },
  login: (state, action) => {
    set('token', action.payload.token)
    return { ...state, ...action.payload }
  },
  clearUser: () => {
    storage.removeItem('persist:root')
    set('token', '')
    return {}
  },
  setUseOffer: (state, action) => {
    return { ...state, user: { ...state.user, offerPackageRenew: action.payload } }
  },
}
const slice = createSlice({
  name: moduleName,
  initialState: initialState,
  reducers,
})

const { reducer, actions } = slice

export const { login, logout, clearUser, setUseOffer } = actions

export default (state, action) => {
  return reducer(state, action)
}
