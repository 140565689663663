import React from 'react'
import { css } from '@emotion/react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Grid from '@material-ui/core/Grid'

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const Spinner = () => {
  return (
    <Grid container direction="row" justify="center" align="center">
      <div className="sweet-loading">
        <PropagateLoader color={'#ff7900'} loading css={override} size={20} />
      </div>
    </Grid>
  )
}

export default Spinner
