import { useEffect } from 'react'
import OneSignal from 'react-onesignal'
import APP_CONFIG from '../configs/appConfig'

const YOUR_APP_ID = '190207352316959'

export default () => {
  const initFacebookSdk = () => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: YOUR_APP_ID,
        cookie: true,
        xfbml: true,
        language: 'vi_VN',
        version: 'v12.0',
      })
    }

    // load facebook sdk script
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  // const initGTM = () => {
  //   ;(function (w, d, s, l, i) {
  //     w[l] = w[l] || []
  //     w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l !== 'dataLayer' ? '&l=' + l : ''
  //     j.async = true
  //     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  //     f.parentNode.insertBefore(j, f)
  //   })(window, document, 'script', 'dataLayer', 'GTM-M5CB5NR')
  // }

  const initGtag = () => {
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11149431951'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'gtag')
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'AW-11149431951')
  }
  useEffect(() => {
    function loadScripts() {
      const reactPixelOptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      }
      initFacebookSdk()
      initGtag()
      //initGTM()
      import('react-facebook-pixel')
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init('3091485277788284', {}, reactPixelOptions)
          ReactPixel.pageView()
        })
    }

    function oneSignalInit() {
      OneSignal.init({
        allowLocalhostAsSecureOrigin: true,
        appId: APP_CONFIG.ONESIGNAL_APP_ID,
      })
      window.removeEventListener('scroll', oneSignalInit)
    }
    window.addEventListener('scroll', oneSignalInit)
    loadScripts()
  }, [])
}
