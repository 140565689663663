import { createSlice, current } from '@reduxjs/toolkit'

import {
  TYPE_PRODUCT,
  PAYMENT,
  TYPE_CART,
  STEPPER,
  APPLIED_TYPE,
  METHOD_TYPE,
  FREE_BOOKPOD,
} from '../../constants'
import { isEmpty } from 'underscore'

const isArray = (arr, length = 0) => Array.isArray(arr) && arr.length > length

const isObject = (obj) =>
  obj && // 👈 null and undefined check
  Object.keys(obj).length > 0 &&
  obj.constructor === Object

const convertObj = (arr, key = 'name') =>
  arr.reduce((a, v) => ({ ...a, [v[key]]: v }), {})

export const isProductSet = (slug) =>
  [
    'bundle-set',
    'starter-set',
    'combo-toypod-set',
    'combo-bookpod-set',
    'full-set',
  ].some((item) => item === slug)

const isProductSetById = ({ id, items }) => {
  const item = items.find(({ _id, slug }) => id === _id && isProductSet(slug))
  return !!item?._id
}
export const checkSlugItem = ({ items }) =>
  items.some(({ catalog: { slug } = {} }) => isProductSet(slug))

export const getStoreItem = ({ items }) => {
  let item = { slug: '', cartType: TYPE_CART.BUY }
  items.map(({ catalog: { slug } = {}, cartType, color }) => {
    const hasProductSet = isProductSet(slug)
    hasProductSet && (item = { slug, cartType, color })
    return item
  })
  return item
}

export const getUpSelling = ({ cartItems }) => {
  const upSelling = cartItems.find(({ slug }) => isProductSet(slug))
  return upSelling?.kidpalUpSelling || []
}

const updateUpSellingWhenRemoveBundle = ({ items, upSellingObj }) => {
  return items.map((item) => {
    const upSellingItem = upSellingObj?.[item?.name]
    return {
      ...item,
      ...(upSellingItem && { price: upSellingItem?.product?.price }),
    }
  })
}

const updateUpSelling = ({ items, upSellingObj }) => {
  return items.map((item) => {
    const upSellingItem = upSellingObj?.[item?.name]
    return {
      ...item,
      ...(item?.originPrice || { originPrice: item?.price }),
      price: upSellingItem ? upSellingItem?.price : item?.originPrice || item?.price,
    }
  })
}

const resetUpSelling = ({ items }) => {
  return items.map((item) => {
    return {
      ...item,
      ...(item?.originPrice || { originPrice: item?.price }),
      price: item?.originPrice || item?.price,
    }
  })
}

export const getStorePackageItem = ({ items }) => {
  let packageItems = []
  let isRent = false
  let rent = {}
  items.map((item) => {
    const { catalog: { slug } = {}, cartType, color, _id } = item
    const hasProductSet = isProductSet(slug)
    hasProductSet && packageItems.push({ slug, cartType, color, id: _id })
    if (cartType && cartType !== TYPE_CART.BUY && cartType !== TYPE_CART.CONVERT) {
      isRent = true
      rent = { ...item, id: item._id }
    }
    return packageItems
  })
  return { isRent, packageItems, rent }
}

export const getPackageRent = ({ items }) => {
  let packageSlug = ''
  items.find(({ catalog: { slug } = {}, cartType }) => {
    const hasProductSet = isProductSet(slug)
    hasProductSet && cartType !== TYPE_CART.BUY && (packageSlug = slug)
    return null
  })
  return packageSlug
}

export const getStorePackageItemById = ({ items, id }) =>
  items.find(({ _id }) => _id === id)

export const checkSwitchSet = ({ items, item }) => {
  const { packageItems = [] } = getStorePackageItem({ items })
  const packageSlug = packageItems?.[0]?.slug
  return packageSlug && isProductSet(item?.catalog?.slug)
}

export const formatNewItems = ({ items, item }) => {
  let newItems = items
  const { isRent, rent } = getStorePackageItem({ items })
  if (isRent) {
    const storeItemById = getStorePackageItemById({ items, id: rent.id })
    const isRemove =
      item.cartType !== TYPE_CART.BUY &&
      storeItemById.catalog.slug !== item.catalog.slug
    isRemove && (newItems = items.filter((item) => item._id !== rent.id))
  }
  return newItems
}

export const hasItemFree = (items) => {
  const freeList = ['64401398c1569c0026f1ef9c']
  const itemFree = items.find((item) => {
    return freeList.indexOf(item._id) !== -1
  })
  if (!isEmpty(itemFree)) {
    return true
  } else {
    return false
  }
}

const initialDefault = {
  items: [],
  voucher: {},
  referral: {},
  delivery: {},
  giftColor: '',
  shared: false,
  payment: PAYMENT.ONEPAY,
  cartDialog: {
    open: false,
    callBack: () => {},
  },
  activeStep: STEPPER.CART,
  total: 0,
  asiaPay: {
    cardName: '',
    cardNumber: '',
    expiredMonth: '',
    expiredYear: '',
    cvv: '',
  },
  note: '',
  shippingFee: 0,
  directDelivery: false,
}

const checkRemoveVoucher = ({ items, applied_type, methodType }) => {
  let isRemoveVoucher = false
  const itemStore = getStoreItem({ items })
  if (applied_type === APPLIED_TYPE.KIDPOD) {
    if (itemStore.slug) {
      if (methodType === METHOD_TYPE.BUY && itemStore.cartType !== TYPE_CART.BUY) {
        isRemoveVoucher = true
      } else if (
        methodType === METHOD_TYPE.RENT &&
        itemStore.cartType === TYPE_CART.BUY
      ) {
        isRemoveVoucher = true
      }
    } else {
      isRemoveVoucher = true
    }
  }

  return isRemoveVoucher
}
export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialDefault,
  reducers: {
    addToCart: (state, action) => {
      let isExists = false
      let quantity = 1

      const items = current(state.items)

      const isSwitch = checkSwitchSet({ items, item: action.payload })
      let upSelling = getUpSelling({ cartItems: items })
      let upSellingObj = convertObj(upSelling)
      const upSellingItem = upSellingObj?.[action.payload?.name]
      const price = upSellingItem?.price
      // const items = formatNewItems({
      //   items: [...currentItems],
      //   item: action.payload,
      // })

      let newItems = items.map((item) => {
        const isEqual = item._id === action.payload._id
        const isSwitchColor = isSwitch && item.color
        isEqual && (isExists = true)

        switch (action.payload.cartType) {
          case TYPE_CART.MONTH_1:
          case TYPE_CART.MONTH_3:
          case TYPE_CART.MONTH_6:
            quantity = action.payload.quantity
            break
          case TYPE_CART.BUY:
            quantity =
              isEqual && item.quantity && !isSwitchColor
                ? action.payload.quantity + item.quantity
                : action.payload.quantity
            //FreeBook
            if (FREE_BOOKPOD.indexOf(action.payload._id) !== -1) {
              quantity = 1
            }
            break
          default:
            break
        }
        return isEqual || isSwitchColor
          ? {
              ...item,
              ...action.payload,
              quantity,
              originPrice: action.payload?.price,
              ...(upSellingItem && { price, originPrice: item.price }),
            }
          : item
      })
      if (isSwitch || isExists) {
        upSelling = getUpSelling({ cartItems: newItems })
        upSellingObj = convertObj(upSelling)
        newItems = updateUpSelling({ items: newItems, upSellingObj })
        if (!isArray(upSelling)) {
          newItems = resetUpSelling({ items: [...newItems] })
        }
        if (isObject(state.voucher)) {
          const isRemoveVoucher = checkRemoveVoucher({
            items: newItems,
            ...state.voucher,
          })
          if (isRemoveVoucher) {
            state.voucher = {}
            state.referral = {}
          }
        }
        state.items = [...newItems]
      } else {
        const item = {
          ...action.payload,
          ...(upSellingItem && { price, originPrice: action.payload.price }),
        }
        let updateItems = [...items]
        updateItems.push(item)
        if (!isArray(upSelling)) {
          upSelling = getUpSelling({ cartItems: updateItems })
          upSellingObj = convertObj(upSelling)
        }

        updateItems = updateUpSelling({ items: updateItems, upSellingObj })
        if (isObject(state.voucher)) {
          const isRemoveVoucher = checkRemoveVoucher({
            items: updateItems,
            ...state.voucher,
          })
          if (isRemoveVoucher) {
            state.voucher = {}
            state.referral = {}
          }
        }
        state.items = [...updateItems]
      }
    },
    updateQuantityItem: (state, action) => {
      const newItems = state.items.map((item) => {
        const isEqual = item._id === action.payload._id
        return isEqual ? { ...item, quantity: action.payload.quantity } : item
      })
      state.items = newItems
    },
    removeCartItem: (state, action) => {
      const items = current(state.items)
      let itemsUpdated = [...items]
      const isRemoveBundle = isProductSetById({ id: action.payload, items })
      if (isRemoveBundle) {
        const upSelling = getUpSelling({ cartItems: items })
        if (isArray(upSelling)) {
          const upSellingObj = convertObj(upSelling)
          itemsUpdated = updateUpSellingWhenRemoveBundle({ items, upSellingObj })
        }
      }
      const newItems =
        itemsUpdated.filter(({ _id }) => {
          return _id !== action.payload
        }) || []

      if (!isArray(newItems)) {
        state.shared = false
        state.voucher = {}
        state.referral = {}
        state.giftColor = ''
      } else if (isObject(state.voucher)) {
        const isRemoveVoucher = checkRemoveVoucher({
          items: newItems,
          ...state.voucher,
        })
        if (isRemoveVoucher) {
          state.voucher = {}
          state.referral = {}
        }
      }
      state.items = newItems
    },
    removeCart: (state) => {
      state.voucher = {}
      state.referral = {}
      state.delivery = {}
      state.giftColor = ''
      state.items = []
      state.shared = false
      state.payment = PAYMENT.ONEPAY
      state.cartType = ''
      state.activeStep = STEPPER.CART
      state.cartDialog = { open: false }
    },
    setVoucher: (state, action) => {
      state.voucher = action.payload
    },
    setCartDialog: (state, action) => {
      state.cartDialog = { ...state.cartDialog, ...action.payload }
    },
    removeVoucher: (state, action) => {
      state.voucher = {}
    },
    updateCartItems: (state, action) => {
      const items = current(state.items)
      const { packageItems = [] } = getStorePackageItem({ items })
      const packageSlug = packageItems?.[0]?.slug
      let newItems = [...items].map((item) => {
        const isSwitchColor = packageSlug === action.payload.slug && item.color
        const isEqual = item._id === action.payload._id
        return (isEqual || isSwitchColor) && action.payload.color !== item.color
          ? {
              ...item,
              ...action.payload,
              quantity: 1,
            }
          : item
      })
      const upSelling = getUpSelling({ cartItems: newItems })
      const upSellingObj = convertObj(upSelling)
      newItems = updateUpSelling({ items: [...newItems], upSellingObj })
      if (isObject(state.voucher)) {
        const isRemoveVoucher = checkRemoveVoucher({
          items: newItems,
          ...state.voucher,
        })
        if (isRemoveVoucher) {
          state.voucher = {}
          state.referral = {}
        }
      }
      state.items = [...newItems]
    },
    updateCartItemsFromUser: (state, action) => {
      state.items = [...action.payload]
    },
    setReferral: (state, action) => {
      state.referral = action.payload
    },
    setGiftColor: (state, action) => {
      state.giftColor = action.payload
    },
    setShared: (state, action) => {
      state.shared = action.payload
    },
    setPayment: (state, action) => {
      state.payment = parseInt(action.payload)
    },
    resetCart: (state, action) => {
      state.items = []
      state.voucher = {}
      state.delivery = {}
      state.shared = false
      state.referral = {}
      state.activeStep = STEPPER.CART
      state.cartDialog = { open: false }
    },
    setDelivery: (state, action) => {
      state.delivery = action.payload
    },
    setDirectDelivery: (state, action) => {
      state.directDelivery = action.payload
    },
    restoreCart: (state, action) => {
      state.delivery = action.payload.delivery
      state.voucher = action.payload.voucher || {}
      state.items = action.payload.productSets
        .map((item) => {
          return {
            ...item.productSet,
            quantity: item.quantity,
            type: TYPE_PRODUCT.KIDPOD,
          }
        })
        .concat(
          action.payload.products.map((item) => {
            return {
              ...item.product,
              quantity: item.quantity,
              type: TYPE_PRODUCT.KIDPAL,
            }
          })
        )
    },
    clearCart: () => {
      return { ...initialDefault }
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setShippingFee: (state, action) => {
      state.shippingFee = action.payload
    },

    setAsiaForm: (state, action) => {
      state.asiaPay = action.payload
    },
    setNote: (state, action) => {
      state.note = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addToCart,
  removeCartItem,
  removeCart,
  updateQuantityItem,
  setVoucher,
  setReferral,
  setGiftColor,
  setShared,
  resetCart,
  setDelivery,
  restoreCart,
  removeVoucher,
  setPayment,
  setCartDialog,
  updateCartItems,
  clearCart,
  setActiveStep,
  updateCartItemsFromUser,
  setTotal,
  setAsiaForm,
  setNote,
  setShippingFee,
  setDirectDelivery,
} = cartSlice.actions

export default cartSlice.reducer
