import { createSlice } from '@reduxjs/toolkit'
import * as dayjs from 'dayjs'

import { CHATBOT_MESSAGE_TYPE, GREETING_ID } from '../constants'

export const DATE_FORMAT = 'YYYY-MM-DD h:mm:s'
const timestamp = dayjs().format(DATE_FORMAT)

const initialMessages = [
  {
    id: GREETING_ID,
    type: CHATBOT_MESSAGE_TYPE.BOT,
    question:
      'Xin chào bạn, tôi giải đáp được các câu hỏi của bạn. Bạn muốn tìm hiểu gì?',
    timestamp,
  },
]

export const slice = createSlice({
  name: 'chatbot',
  initialState: {
    messages: initialMessages,
    ipAddress: {},
  },
  reducers: {
    addMessage: (state, action) => {
      return { ...state, messages: [...state.messages, action?.payload] }
    },
    addAddressIP: (state, action) => {
      return { ...state, ipAddress: action?.payload }
    },
    resetMessage: (state) => {
      return { ...state, messages: initialMessages, ipAddress: {} }
    },
  },
})

// Action creators are generated for each case reducer function
export const { addMessage, resetMessage, addAddressIP } = slice.actions

export default slice.reducer
